import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Checkbox, FormHelperText } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import ChoiceButton from 'components/global/mui4/ChoiceButton';
import FormControlLabel from 'components/global/mui4/FormControlLabel';

const ModifySession = ({ classes, intl, duration, onStateChange }) => {
  const [modifyDuration, setModifyDuration] = useState(false);
  const [sessionDuration, setSessionDuration] = useState(60);
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    onStateChange({ modifyDuration, sessionDuration, consent });
  }, [modifyDuration, sessionDuration, consent, onStateChange]);

  const handleModifyDurationChange = value => {
    setModifyDuration(value);
  };

  const handleSessionDurationChange = value => {
    setSessionDuration(value);
  };

  const handleConsentChange = event => {
    setConsent(event.target.checked);
  };

  if (duration <= 30) {
    return <></>;
  }
  return (
    <div className={classes.reasonBox} style={{ marginBottom: 20 }}>
      <p className={classes.subTitle}>
        {intl.formatMessage({
          defaultMessage:
            'Do you need to shorten the duration for this session?',
        })}
      </p>
      <ButtonGroup style={{ marginBottom: 20 }}>
        <ChoiceButton
          label={intl.formatMessage({ defaultMessage: 'No' })}
          selected={!modifyDuration}
          onClick={() => handleModifyDurationChange(false)}
        />
        <ChoiceButton
          label={intl.formatMessage({ defaultMessage: 'Yes' })}
          selected={modifyDuration}
          onClick={() => handleModifyDurationChange(true)}
        />
      </ButtonGroup>
      {modifyDuration && (
        <>
          <p className={classes.subTitle}>
            {intl.formatMessage({
              defaultMessage: 'Set New Session Duration',
            })}
          </p>
          <ButtonGroup style={{ marginBottom: 20 }}>
            {duration > 30 && (
              <ChoiceButton
                label={intl.formatMessage({ defaultMessage: '30 Minutes' })}
                selected={sessionDuration === 30}
                onClick={() => handleSessionDurationChange(30)}
              />
            )}
            {duration > 60 && (
              <ChoiceButton
                label={intl.formatMessage({ defaultMessage: '60 Minutes' })}
                selected={sessionDuration === 60}
                onClick={() => handleSessionDurationChange(60)}
              />
            )}
            {duration > 90 && (
              <ChoiceButton
                label={intl.formatMessage({ defaultMessage: '90 Minutes' })}
                selected={sessionDuration === 90}
                onClick={() => handleSessionDurationChange(90)}
              />
            )}
          </ButtonGroup>

          <FormControlLabel
            label={intl.formatMessage({
              defaultMessage:
                'I acknowledge that I have received verbal consent from my client to modify the duration for this appointment and be charged accordingly',
            })}
            control={
              <Checkbox checked={consent} name="checkedB" color="primary" />
            }
            onChange={handleConsentChange}
          />
          {!consent && (
            <FormHelperText error>
              {intl.formatMessage({
                defaultMessage:
                  'You must acknowledge that the client has consented to the new appointment duration in order to proceed.',
              })}
            </FormHelperText>
          )}
        </>
      )}
    </div>
  );
};

ModifySession.propTypes = {
  classes: PropTypes.shape({
    reasonBox: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.object.isRequired,
  onStateChange: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
};

export default injectIntl(ModifySession);
