import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
import { injectIntl } from 'react-intl';
import { headers } from '../../actions/defaults';
import { API_URL } from '../../environment';

class ClientSelect extends Component {
  constructor() {
    super();
    this.state = {
      clients: [],
      selectValue: null,
      loading: true,
    };
  }

  componentDidMount() {
    axios
      .get(`${API_URL}/practice/clients/client_selector`, headers())
      .then(response => {
        const options = response.data
          .map(client => ({ label: client.full_name, value: client.id }))
          .sort((a, b) => a.label.localeCompare(b.label));
        this.setState({ clients: options });
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  handleChange = value => {
    this.setState({ selectValue: value }, () =>
      this.props.onChange(value ? value.value : null),
    );
  };

  render() {
    const { clients, selectValue, loading } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <Select
        className="global-client-select"
        defaultValue={null}
        options={clients}
        value={selectValue}
        isClearable
        onChange={this.handleChange}
        isSearchable
        isLoading={loading}
        name="client"
        noOptionsMessage={() =>
          formatMessage({ defaultMessage: 'No results found' })
        }
        placeholder={formatMessage({ defaultMessage: 'Client ...' })}
      />
    );
  }
}

ClientSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ClientSelect);
